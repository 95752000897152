.userDashboardContainer {
  display: grid;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  grid-template-columns: 18% auto;
}

.appSidebar {
  background-color: #000;
  height: 100%;
  position: fixed;
  width: 18%;
  padding-top: 50px;
}

.mainContentWrapper {
  width: 100%;
  overflow-y: auto;
  position: relative;
  /* padding-bottom: 50px; */
  height: 100vh;
}

.bg-dashboard {
}

.mobile {
  display: none;
}

.mainContentWrapper::-webkit-scrollbar {
  display: none;
}

/* .userDashboardContainer::-webkit-scrollbar {
  display: none;
} */

/* .sidebar-mobile-view {
  display: none;
} */
.close-icon {
  display: none;
}

.dashboard-img {
  background-image: url("../images/websiteBg.png");
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  padding-bottom: 100px !important;
  /* background-image: linear-gradient(
    45deg,
    hsl(333deg 99% 27%) 0%,
    hsl(327deg 98% 25%) 8%,
    hsl(321deg 90% 24%) 17%,
    hsl(314deg 76% 23%) 25%,
    hsl(307deg 81% 25%) 33%,
    hsl(300deg 81% 27%) 42%,
    hsl(292deg 76% 33%) 50%,
    hsl(283deg 73% 34%) 58%,
    hsl(273deg 70% 35%) 67%,
    hsl(262deg 68% 35%) 75%,
    hsl(263deg 66% 32%) 83%,
    hsl(264deg 64% 29%) 92%,
    hsl(265deg 62% 26%) 100%
  );
  height: 100% !important; */
}

.item-container-stats {
  /* padding: 0 0rem; */
}

.bg-container {
  position: fixed;
  width: 100%;
  height: 100vh;
}

@media (max-width: 700px) {
  .item-container-stats {
    padding: 0.2rem;
  }
  .userDashboardContainer {
    grid-template-columns: 1fr;
  }
  .close-icon {
    display: block;
  }

  .appSidebar {
    z-index: 999;
    top: 0;
    left: 0;
    height: auto;
    padding-top: 20px;
    width: 250px;
    padding-bottom: 20px;
  }

  .mainContentWrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    margin-left: 0% !important;
  }
  .mobile {
    display: block;

    margin-top: 30px;
    padding-bottom: 15px;
  }
  .name-container {
    display: flex;
    justify-content: space-between !important;
  }

  .sidebar-mobile-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dashboard-img {
    background-repeat: no-repeat;

    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    /* width: 100vw; */
  }
}
