.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 900px;
  margin: 0 auto;
  background: rgba(133, 113, 77, 0.2);
  border: 1px solid #85714d;
  border-radius: 10px;
}

.content-table thead tr {
  /* background-color: #85714d; */
  color: #fff;
  text-align: left;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
}

.content-table tbody tr {
  border-bottom: 0.5px solid #ffffff;
}

.notificationContainer {
  background: rgba(133, 113, 77, 0.2);
  border: 1px solid #85714d;
}

.borderBottom {
  width: 100%;
  height: 0.5px;
  background-color: #d9d9d9;
  margin-top: 3px;
}

.count-container {
  display: flex;

  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  position: relative;
  font-size: 16px;
  border-radius: 0.5em;
  background-color: #21242b;
  border-bottom: 10px solid #85714d;
}

.text-num {
  text-align: center;
  font-size: 9px;
  word-wrap: break-word;
  font-weight: 500;
}

.num {
  background-color: #85714d;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 13px;
}
