.custom-message {
  position: fixed;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  z-index: 9999;
}

.context-box {
}
