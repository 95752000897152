body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999;
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 999;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  max-width: 800px;
  min-width: 800px;
  z-index: 9999;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.deposite-box {
  width: 70%;
  padding: 42px 48px;
  background-color: black;
  border-radius: 10px;
  z-index: 9999 !important;
}
.deposite-header {
  margin-bottom: 10px;
  text-align: center;
  font-size: 13px;
}

.deposite-sub-text {
  font-size: 10px;
  font-weight: 500;
  margin-top: 10px;
}

/* NEW MODAL */
.popup {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background: rgba(49, 49, 49, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 800px;
  /* height: 600px; */
  overflow-y: auto;
  /* background-color: black; */
  border-radius: 10px;
}

.close-btn-modal {
  position: absolute;
  top: 16px;
  right: 20px;
}

@media (max-width: 700px) {
  .close-btn-modal {
    position: absolute;
    top: 30px;
    right: 20px;
  }
}
