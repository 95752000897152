@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary-color: #85714D;
  --primary-dark-alt: #54472d3e;
  --primary-dark: #54472d;
  --bg-color: #3E3D3D;
  --alt-color: #4C4740;
  --shadow: box-shadow: 0px 4px 4px 0px #00000026;
  --success: #2CC168;
  --danger: #C12C2C;
}

* {
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-color);
  /* width: 100%; */
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

.home-bg-image{
background-color: #3E3D3D;

}

.app-home{
  background-image: url(../images/navBanner.png);
  height: 70vh;
  width: '100%';
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat; 
}

.big-display {
  font-size: 64px;
  font-weight: 700;
  line-height: 78px;
  letter-spacing: -0.3000000119px;
}

.heading-six {
  font-size: 20px;
}

.footer-text{
  width: 80%;
}

.heading-five {
  font-size: 22px;
  width: 760px;
}


.long-text{
  /* height: 0; */
  /* overflow: hidden; */
}


.heading-two {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: -0.3000000119px;
  text-align: left;
}

.base-text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.3000000119px;
}

.btn {
  padding: 10px 20px;
  color: #ffffff;
  transition: all 300ms ease-out;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.btn-primary {
  background-color: var(--primary-color) !important;
}

.btn-primary:hover {
  background-color: var(--primary-dark) !important;
}

.btn-secondary {
  border: 1px solid var(--primary-color);
  padding: 9px 24px;
  transition: all 300ms ease-out;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: transparent !important
}

.btn-secondary:hover {
  background-color: var(--primary-dark-alt);
}

.btn-link {
  transition: all 300ms ease-out;
}

.btn-send {
  transition: all 300ms ease-out;
}

.btn-send:hover,
.btn-link:hover {
  opacity: 0.5;
}

.footer-group h5 {
  color: var(--primary-color);
}

.footer-links {
  line-height: 40px;
}

.footer {
  background-color: #000000;
  /* display: flex;
  justify-content: space-between; */
}

.primary-color {
  color: var(--primary-color);
}

.bg-danger-color {
  background-color: var(--danger);
}

.bg-success-color {
  background-color: var(--success);
}

.bg-alt-color {
  background-color: var(--alt-color);
}

.home-img {
  height: 100% !important;
  width: 100%;
  filter: brightness(50%);
}

.hero {
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(../newImages/learn.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
}

.imageone,
.about-bg {
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(../images/about-bg.svg);
  height: 100%;
  width: 50%;
 
  top: 0;
  position: absolute;
  right:0;
  background-blend-mode: overlay;
  background-size: contain, cover;
}

.quest {
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(../newImages/quest.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  height: 528px;
}

.single-quest img {
  width: 800px;
}

.append {
  position: relative;
}

.append input {
  color: #000000;
}

.append button {
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translate(-3%, -50%);
}

.table-auto td {
  padding: 16px 0px;
  border-bottom: 1px solid #808080;
}

.card {
  /* position: relative; */
  background-color: var(--primary-dark-alt);
  border: 1px solid var(--primary-color);
  /* width: 23rem; */
  /* height: max-content; */
}

/* Custom CSS for the Reactour component */
.custom-tour {
  max-width: 60% !important; 
  font-size: 12px !important;
}



.auth-img-bg{
  background-color: rgba(0, 0, 0, 0.5);
}

.card img {
  height: 243px;
  width: 357px;
}

.custom-img{
  display: block;

  margin: 0 auto;
  margin-top: 25px;
}

.errorText{
  color: var(--danger);
  height: 10px;
  margin-bottom: 10px;
}

.checkBoxText{
  font-size: 16;
  font-weight: 500;
  
}
.box{
  width: 21px;
  height: 21px;
  background-color: #fff;
  display: block;
}

.boxSelected{
  width: 21px;
  height: 21px;
  background-color: #000;
  display: block;
}
.action-btn{
  width: 119px;
height: 42px;
}

.spinner{
  background-color: #85714D;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
  background: conic-gradient(#85714D, transparent);
  animation: rotate 1s linear infinite;
}

.spinner::before,
.spinner::after{
content: "";
position: absolute;
border-radius: 50%;
}

.spinner::before{
  width: 80px;
  height: 80px;
  top: 10px;
  left: 10px;
  background-color: #3E3D3D;
}

.spinner::after{
  height: 10px;
  width: 10px;
  background-color: #85714D;
  top: 0;
  left: 45px;
}

.loaderText{
  font-weight: 700;
  color: #ffffff;
  margin-top: 10px;
 text-align: center;
}

@keyframes rotate {
  from{transform: rotateZ(0);}
  to{transform: rotateZ(360deg);}
}

.editIcon{position: absolute;
margin-left: 20px;
margin-top: 10px;
color: #000;
}

.app-footer{
  background-color: #000;
  padding-top: 30px;
  padding-bottom: 30px;
}

.app-footer-container{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.footer-line{
  width:200px;
  height: 0.5;
  background-color: #54472d;
}

.footer-box1{
  flex: 1;

}

.footer-box2{
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-left: 300px;
}
.footer-head{
  font-size: 18px;
  color: #85714D;
}

.withdrawBtn{
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #85714D;;
  height: 150px;
  width: 150px;
  border-radius: 10px;
  
}
.upload-btn-wrapper:hover{
  cursor: pointer !important;
}

.btnUpload {
  cursor: pointer;
  /* border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold; */
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.first-bg{
  background-image: url("../images/first.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  object-fit: cover;
}
.second-bg{
  background-image: url("../images/second.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  object-fit: cover;
}
.third-bg{
  background-image: url("../images/third.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  object-fit: cover;
}
.fourth-bg{
  background-image: url("../images/fourth.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  object-fit: cover;
}
.fifth-bg{
  background-image: url("../images/fifth.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.otp-field{
  width: 50px;
  height: 50px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  background-color: transparent;
  outline: none;
  border: 1px solid #85714D;
 
}

.alert_item {
  max-width: 600px;
  width: 100%;
  background: #fff;
  margin-bottom: 25px;
  padding: 15px 20px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
}

.success_item{
  background: #85714D;
}
.text p{
  color: #fff;
  font-weight: 500;
}



.icon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #54472d;
  margin-right: 20px;
}

.dashboardWrapper{
  padding: 0 5rem;
}

.admin-item-box{
  width: 300px !important;
  font-size: 12px;
  text-align: left;
  margin-right: 30px;
  margin-bottom: 10px;
}




.bottom-margin{
  border-bottom: 1px solid #85714d;
  padding: 10px 0px;
}
.item-accordian{
  margin: 10px 0;
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}

.item-accordian.show{
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1,0,1,0);
}

.btn-action{
  background-color: #2CC168;
  color: #000;
  width: 5%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 10px;
}




/* CUSTOM DROPDOWN INPUT */

.dropDown{
  width: 170px;
  margin: 0 auto;
  position: relative;
  user-select: none;
}

.dropDown .dropDown-btn{
  padding: 15px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dropDown .dropdown-content{
  position: absolute;
  top: 110%;
  padding: 15px;
  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, blu0e, 0.6);
  font-weight: bold;
  color: #333;
  width: 100%;
  cursor: pointer;
}

.dropDown .dropdown-content .dropdown-item{
  padding: 10px;
transition: all 0.2s;
color: #000000 !important;
}

.custom-bg-wrapper{
  background: rgba(133, 113, 77, 0.2);
border: 1px solid #85714D;
padding: 20px;
}

.amount-bg{
  background: #85714D;
  padding: 10px;
  border-radius: 10px;
  width: 40%;
}

.middle-box{
  background: #4C4740;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.divider{
  border: 0.5px solid #FFFFFF;
  right: 80px;
  position: relative;
}

[data-tooltip]{
  position: relative;
  cursor: default;
}

[data-tooltip]::after{
  position: absolute;
  width: 200px;
  left: calc(50%-10px);
  content:attr(data-tooltip);
  bottom: 125%;
  text-align: center;
  box-sizing: border-box ;
}

.card-body{
  width: 100%;
  height: 100%;
  top: 0;
  right: -100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px;
  font-size: 11px;
  padding-top: 30px;
  text-align: center;
  line-height: 18px;
  transition: 1s;
}

.card-img:hover .card-body{
right: 0;
}

.tool-tip{
  z-index: 9999 !important;
}



.inputWithoutCount::-webkit-inner-spin-button, 
.inputWithoutCount::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.home-content{
  margin-top: 80px;
}

@media (max-width: 768px) {

  .dashboardWrapper{
    padding: 0 0rem;
  }

  .home-content{
    margin-top: 60vh;
  }

  .custom-tour {
    max-width: 90% !important; 
    font-size: 12px !important;
  }

  .btn-primary,
  .btn-secondary {
    font-size: 12px !important;
    padding: 9px 20px;
  }

  .otp-field{
    width: 40px;
    height: 40px;
  }

  /* .mobile-btn{
    width: 100px !important;
    padding-left: 5px;
    padding-right: 10px;
  } */

  .logo {
    width: 96px;
  }

  .big-display {
    font-size: 20px;
  }

  .quest {
    background-position-x: center;
  }

  .heading-five {
    font-size: 12px;
  }

  .footer-group .heading-five {
    font-size: 16px;
  }

  .about-header {
    background-color: rgba(0, 0, 0, 0.7);
    background-image: url(../images/about-bg.svg);
    z-index: 1000;
    margin-top: -20%;
    background-size: contain;
    background-position-y: -60%;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
  }

  .imageone{
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background-size: contain, cover !important;
    height: 370px !important;
    z-index: -9999;
  }

  .heading-two {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.3000000119px;
    text-align: left;
    z-index: 9999 !important;
    margin-top: -170px;
  }

  .heading-six {
    font-size: 12px !important;
  }

  .humbugerMenu{
    top: 75px !important;
    position: absolute;
  }

  input,
input::-webkit-input-placeholder {
    font-size: 10px;
    
}
.app-footer-container{
  flex-direction: column;
}

.footer-box2{
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
  margin-top: 20px;
}

.box{
  width: 15px;
  height: 15px;
  background-color: #fff;
  display: block;
}

.boxSelected{
  width: 15px;
  height: 15px;
  background-color: #000;
  display: block;
}

.item-text{
  font-size: 14px !important;
  position: relative;
  left: -9px;
  line-height: 1rem !important;
  top: 5px;
}

.withdrawBtn{
  margin-top: 30px;
  position: static;
}

.card {

  background-color: var(--primary-dark-alt);
  border: 1px solid var(--primary-color);
  height: max-content;
}

.app-home{
  background-image: url(../newImages//mobileHome.png);
  height: 70vh;
  width: '100%';
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat; 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
 
}

