@tailwind base;
@tailwind components;
@tailwind utilities;

.form-div {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.quest-bg {
  position: absolute;
  /* background-color: rgba(62, 61, 61, 0.7); */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.disable-bg {
  position: relative;
}
